<template>
    <b-row style="max-width:375px" class="centered-container">
        <b-row class="mt-4">
            <b-col class="header-container mb-3">
                <p v-if="otpIsRequired" style="color: red; text-align: center;">
                    All OTP field is required
                </p>
                <h2 class="centered-header">Please enter OTP</h2>
                <span class="span-item">
                    Please check your email for the OTP
                </span>
            </b-col>
        </b-row>
        <b-row class="centered-content ">
            <div class="otp-input">
                <input v-for="(digit, index) in otpDigits" :key="index" :id="index" required v-model="otpDigits[index]"
                    type="tel" pattern="^\d{1,9}$" ref="otpInput" @keyup="handleKeyDown($event, index)" class="otp-box"
                    maxlength="1" :tabindex="index" />
            </div>

            <div class="col-12 mt-4 btn-flex-fd-row-jc-sb">
                <span style="cursor: pointer;display:flex;align-items:center;color:#000; opacity: .8;"
                    @click.prevent.stop="goBack">Back</span>
                <b-button variant="primary" size="sm" @click.prevent.stop="verifyOtp">Verify</b-button>
            </div>

            <div class="col-12 text-center my-4">
                <span>Don’t receive your code? &nbsp;</span>
                <span v-if="disableResend" class="resend-otp" @click.prevent.stop="resendOtp"> Resend</span>
                <span v-else class="resend-otp"> {{ countDown }}</span>
                <!-- <b-button v-if="!otp" size="sm" :disabled="disableResend" class="main__login--button p-3 mx-auto"
                    @click.prevent.stop="resendOtp">Resend&nbsp;{{ disableResend ? `in ${countDown}` : "" }}</b-button> -->
            </div>
        </b-row>
    </b-row>
</template>

<script>


export default {
    name: "OTPScreen",
    components: {},
    props: {
        otpDigits: {
            type: Array,
            required: true,
        },
        showOtp: {
            type: Boolean,
        },
    },
    data() {
        return {
            countDown: 120,
            disableResend: true,
            otpIsRequired: false
        }
    },
    methods: {
        handleKeyDown(event, index) {
            console.log('Key Pressed:', event);
            // console.log('Current Index:', index);
            if (event.key === 'Backspace' && index > 0 && !this.otpDigits[index]) {
                // If Backspace is pressed and the current box is empty
                // Clear the previous box
                this.otpDigits[index - 1] = '';
                // Focus on the previous box
                this.$refs.otpInput[index - 1].focus();
            } else if (event.key.match(/[0-9]/) && index < 3) {
                // } else if (event.target.tabIndex.toString().match(/[0-9]/) && index < 3 && this.otpDigits[index] !== '') {
                this.$refs.otpInput[index + 1].focus();
            }

            this.$emit('update-otp-digits', this.otpDigits);
        },
        goBack() {
            console.log('back');
            this.$emit('goBack', this.showOtp);
        },
        CheckLengthOfOTP(otpDigits) {
            const otp = otpDigits.filter(digit => digit !== '');
            // console.log(otp.length);
            return otp.length === 4
        },
        verifyOtp() {

            // console.log('this.otpDigits.length', this.CheckLengthOfOTP(this.otpDigits));
            if (!this.CheckLengthOfOTP(this.otpDigits)) {
                this.otpIsRequired = true
                return
            } else {
                this.otpIsRequired = false
                this.$emit('verify', this.otpDigits);
            }
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                this.disableResend = true;
            }
        },
        resendOtp() {
            this.disableResend = false;
            this.countDown = 120;
            this.countDownTimer();
            this.$emit('resendOtp', { countDown: this.countDown, disableResend: this.disableResend });
        }
    },

}
</script>

<style>
.otp-input {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
}

.otp-box {
    display: flex;
    width: 61.972px;
    height: 61.972px;
    gap: 10px;
    flex-shrink: 0;
    text-align: center;
    background: var(--neutral-soft-grey);
    border: none;
    border-radius: 10px;
}

.resend-otp {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
}
</style>