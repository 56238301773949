<template>
    <CommonLayoutsVue layoutContentStyle="margin-top: 2rem">
        <template v-slot:child>
            <b-row v-if="!showOtp" class="login-container">
                <b-row class="mt-4">
                    <b-col class="l-header-container">
                        <h2 class="login-header">{{ $gettext("Welcome_to_Zaajira") }}</h2>
                    </b-col>
                </b-row>

                <div class="login-content">
                    <ValidationObserver ref="register">
                        <b-form class="customized-form py-4" style="width: 100%;">
                            <b-row class="">
                                <div class="col-lg-12 mb-3">
                                    <CustomDropdown :selectedOption="usertype" :options="usertypeOptions"
                                        @update:selectedOption="updateUserType"></CustomDropdown>
                                </div>
                                <div class="col-12">
                                    <ValidationProvider name="FullName" rules="required" v-slot="{ errors, classes }">
                                        <b-form-input id="FullName" :class="classes" placeholder="Full Name"
                                            v-model="user_username" type="text">
                                        </b-form-input>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="usertype === 'Email'">
                                    <ValidationProvider name="email" rules="required|Email"
                                        v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="email">
                                            <b-form-input :class="classes" id="email" size="sm" type="email"
                                                autocomplete="off" v-model="email"
                                                placeholder="Email Address"></b-form-input>
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-else>
                                    <ValidationProvider name="phone" rules="required|Phone"
                                        v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="phone">
                                            <b-form-input :class="classes" id="phone" size="sm" type="tel"
                                                v-model="mobile" placeholder="Mobile number"></b-form-input>
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] ? "Mobile number (eg. 256123456789 or +256123456789)" :
                                                ''
                                                }}</span>
                                            <!-- <span>{{ errors[0] }}</span> -->
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="usertype === 'Email'">
                                    <ValidationProvider name="user-password" rules="required|max:15|min:8"
                                        vid="confirmation" v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="user-password">
                                            <b-form-input :class="classes" id="user-password" v-model="password"
                                                :type="passwordType ? 'password' : 'text'" size="sm"
                                                placeholder="Input your password"></b-form-input>
                                            <img @click="viewPassword" style="position: absolute;top: 25%;right: 30px;"
                                                src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12" v-if="usertype === 'Email'">
                                    <ValidationProvider rules="required|confirmed:confirmation"
                                        v-slot="{ errors, classes }">
                                        <b-form-group label="" label-for="confirm-password">
                                            <b-form-input :class="classes" id="confirm-password"
                                                v-model="confirmPassword" :type="passwordType ? 'password' : 'text'"
                                                size="sm" placeholder="Re enter your password"></b-form-input>
                                            <img @click="viewPassword" style="position: absolute;top: 25%;right: 30px;"
                                                src="@/assets/zaajira-candidate/assets/icons/eye.svg" alt="" srcset="">
                                        </b-form-group>
                                        <div class="error-msg" :class="classes">
                                            <span>{{ errors[0] }}</span>
                                        </div>
                                    </ValidationProvider>


                                </div>
                                <div class="col-12">
                                    <vue-recaptcha :size="recaptchaSize" :sitekey="reCaptchaSiteKey"
                                        @verify="captchaVerify" @expired="captchaExpired" @error="captchaError"
                                        ref="recaptcha-login" class="d-flex justify-content-center mx-auto"
                                        style="overflow: hidden"></vue-recaptcha>
                                </div>


                                <div class="col-12 mt-3 login-btn">
                                    <b-form-checkbox variant="primary" v-model="TermsConditions" class="">
                                        <router-link to="/terms-and-conditions">
                                            {{ $gettext("Terms_and_Conditions") }}
                                        </router-link>
                                        <!-- <span style="color:#9C9CA4;">Forgot Password</span> -->
                                    </b-form-checkbox>
                                    <b-button variant="primary" size="sm" @click.prevent.stop="ValidateInput">{{
                                        $gettext("Register") }}</b-button>
                                </div>
                                <div class="col-12 text-center">
                                    <span>{{ $gettext("Already_have_account") }}</span>
                                    <router-link to="candidate-login">&nbsp; {{ $gettext("Login") }}</router-link>
                                </div>
                            </b-row>

                        </b-form>
                    </ValidationObserver>

                </div>
            </b-row>

            <OTPScreen v-else :otpDigits="otpDigits" @resendOtp="sendOtp" @goBack="showOtp = false"
                @verify="handleOTPVerification" @update-otp-digits="updateOTPDigits" />

        </template>
    </CommonLayoutsVue>
</template>

<script>
import CommonLayoutsVue from '../../../components/Layouts/Common-Layouts.vue'
// import CenteredLayoutsVue from '../../../components/Layouts/Centered-Layout.vue'
import CustomDropdown from '../../../components/Dropdown/Dropdown.vue'
import OTPScreen from '../../../components/Otp-Screen/Otp-screen.vue'
import Multiselect from 'vue-multiselect';
import VueRecaptcha from "vue-recaptcha";
import { socialLoginClientId, reCaptchaSiteKey, FRONTEND_BASE_URL } from "@/config";

import CandidateService from "@/apiServices/CandidateService";

export default {
    name: "Register",
    components: { CommonLayoutsVue, CustomDropdown, OTPScreen, Multiselect, VueRecaptcha, },
    data() {
        return {
            usertype: "Email",
            usertypeOptions: [
                "Email",
                "Phone",
            ],
            dropdownOption: false,
            email: '',
            mobile: '',
            user_username: '',
            user_first_name: '',
            user_last_name: '',
            password: '',
            confirmPassword: '',
            passwordType: true,
            captchaValue: null,
            TermsConditions: false,
            otpDigits: ['', '', '', ''],
            showOtp: false,
            reCaptchaSiteKey: reCaptchaSiteKey,
            recaptchaSize: 'normal',
            isMobileMenu: window.innerWidth <= 367
        }
    },
    methods: {
        updateUserType(usertype) {
            this.usertype = usertype;
        },
        viewPassword() {
            this.passwordType = !this.passwordType
        },

        ValidateInput() {
            if (!this.TermsConditions) {
                this.$toasted.show("Please check Terms & conditions", {
                    duration: 6000,
                    type: "error",
                    // icon: "clear",
                });
                return
            }
            this.$refs.register.validate().then((result) => {
                console.log("aman");
                console.log(result);
                if (result) {
                    if (this.captchaValue) {
                        this.signUp(3, null);
                    } else {
                        this.$toasted.show("Please verify captcha", {
                            duration: 6000,
                            type: "error",
                            icon: "clear",
                        });
                    }
                    return;
                } else {
                    return;
                }
            });
        },
        updateOTPDigits(updatedDigits) {
            // Handle the updated OTP digits received from the child component
            this.otpDigits = updatedDigits;
        },
        countDownTimer() {
            if (this.countDown > 0) {
                setTimeout(() => {
                    this.countDown -= 1;
                    this.countDownTimer();
                }, 1000);
            } else {
                this.disableResend = false;
            }
        },
        sendOtp(countDownOrDisableResend) {
            // this.$toasted.show(`Otp has been sent!`, { duration: 6000 });
            // console.log('countDownOrDisableResend', countDownOrDisableResend);
            // this.signUp()
            this.signUpWithMobile()

        },
        handleOTPVerification(OTP) {
            // Handle OTP verification logic here
            console.log('this.OTPdigits', this.otpDigits);
            console.log('OTP Verified!', OTP);

            const payload = {
                admin: null,
                role: 3,
                user_username: this.user_username,
                // user_mobile_number: '+919691060747',//this.mobile
                user_mobile_number: '+' + this.removePlus(this.mobile),
                user_email: this.email === '' ? '' : this.email,
                referal_code: '29',
                user_password: this.password === '' ? 'password' : this.password,
                otp:
                    OTP[0].toString() +
                    OTP[1].toString() +
                    OTP[2].toString() +
                    OTP[3].toString(),
                user_first_name: this.user_first_name,
                user_last_name: this.user_last_name

            }
            console.log('VerifyOTP', payload);
            CandidateService.Register(payload)
                .then(async (response) => {
                    console.log("Verify email response: ", response);
                    if (response.data.status) {
                        this.showOtp = false;
                        this.$toasted.show(`Registration Successful`, {
                            duration: 6000,
                            type: "success",
                            icon: "check",
                        });

                        const LoginPayload = {
                            role_id: 3,
                            email: this.email === '' ? '' : this.email,
                            // mobile_number: '+' + '919691060747',// this.mobile === '' ? '' :` +${this.mobile}`,
                            mobile_number: this.mobile === '' ? '' : '+' + this.removePlus(this.mobile),
                            password: this.password === '' ? 'password' : this.password,
                        }

                        try {
                            const res = await CandidateService.Login(LoginPayload)
                            // this.$store.dispatch("setUserID", res.data.data.user.user_id);
                            delete res.data.data.user.user_password
                            const payload = {
                                userData: res.data.data.user,
                                userName: res.data.data.user.user_username,
                                userEmail: res.data.data.user.user_email,
                                userId: res.data.data.user.user_id,
                                userRole: res.data.data.user.role_id,
                                userToken: res.data.data.token,
                                isLoggedIn: true
                            };

                            console.log("THIS PERSON IS CANDIDATE");
                            console.log(payload);
                            if (payload.userRole === 3) {
                                this.$store.dispatch("onSetUserSigninValues", payload);
                            }
                            // set cookies
                            this.$cookies.set("username", payload.userName);
                            this.$cookies.set("AIAPADHASHID", payload.userToken);
                            this.$cookies.set("user_id", payload.userId);
                            this.$cookies.set("role_id", payload.userRole);
                            console.log("now checking if user is first login ?");
                            if (res.data.data.user.first_login == "Y") {
                                try {
                                    console.log("This is the first time user logging so set the login flag");
                                    let setLoginFlagResponse = await CandidateService.SetLoginFlag();
                                    console.log("SetLoginFlag Response : ", setLoginFlagResponse);
                                    this.$router.push({ path: '/candidate/create-visiting-card' })
                                } catch (error) {
                                    console.log(error.message);
                                }
                            } else {
                                console.log("Not the first time login");
                            }
                        } catch (error) {
                            console.log(error.message);
                        }



                    } else {
                        this.$toasted.show(`${response.data.data}`, {
                            duration: 6000,
                            type: "error",
                            // icon: "clear",
                        });
                        // this.loader.hide();
                    }
                    // this.$toasted.show(`${res.data.data}`, { duration: 6000 });

                })
                .catch((err) => {
                    console.log("[Backend] Verify email error: ", err);
                    this.loader.hide();
                    this.$toasted.show("Registration Failed!", {
                        duration: 6000,
                        type: "error",
                        icon: "clear",
                    });
                });

        },

        signUpWithEmail() {
            // const mobileNumberRegex = /^(?:\+256|\+255|\+254)\d{9}/;
            const mobileNumberRegex = /^(256|255|254)\d{9}$/;
            let payload = {
                admin: null,
                role: 3,
                user_username: this.user_username,
                user_mobile_number: "+" + this.removePlus(this.mobile),
                // mobileNumberRegex.test(this.mobile) == true
                //     ? this.mobile
                //     : null,
                user_email: this.email === '' ? '' : this.email,
                referal_code: '',
                user_password: this.password === '' ? '' : this.password,
                user_first_name: this.user_first_name,
                user_last_name: this.user_last_name
            }
            console.log('payload', payload);

            let loader = this.$loading.show();

            // Call Register Email Service
            CandidateService.verifyEmail(payload)
                .then((res) => {
                    if (res.data.status) {
                        console.log("Verify email response: ", res);
                        this.showOtp = true;
                    } else {
                        this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
                        this.$router.push({ name: 'Candidate-login' })
                    }
                    this.$toasted.show(`${res.data.data}`, { duration: 6000 });
                    loader.hide();
                })
                .catch((err) => {
                    console.log("[Backend] Verify email error: ", err);
                    loader.hide();
                    this.$toasted.show("Server Error", {
                        duration: 6000,
                        icon: "clear",
                        type: "error",
                    });
                });
        },
        removePlus(mobileNumber) {
            return mobileNumber.replace(/\+/g, '');
        },
        signUpWithMobile() {
            // const mobileNumberRegex = /^(?:\+256|\+255|\+254)\d{9}/;
            const mobileNumberRegex = /^(256|255|254)\d{9}$/;
            let payload = {
                admin: null,
                role: 3,
                user_username: this.user_username,
                user_mobile_number: '+' + this.removePlus(this.mobile),
                // user_mobile_number:
                //     mobileNumberRegex.test(this.mobile) == true
                //         ? this.mobile
                //         : null,
                // user_mobile_number: '+919691060747',
                // // mobileNumberRegex.test(this.mobile) == true
                // //     ? this.mobile
                // //     : null,
                user_email: this.email === '' ? '' : this.email,
                referal_code: '',
                user_password: this.password === '' ? '' : this.password,
                user_first_name: this.user_first_name,
                user_last_name: this.user_last_name
            }
            console.log('payload', payload);

            let loader = this.$loading.show();

            // Call Register Email Service
            CandidateService.verifyEmail(payload)
                .then((res) => {
                    if (res.data.status) {
                        console.log("Verify email response: ", res);
                        this.showOtp = true;
                    } else {
                        this.$refs["recaptcha-1"] && this.$refs["recaptcha-1"].reset();
                        this.$router.push({ name: 'Candidate-login' })
                    }
                    this.$toasted.show(`${res.data.data}`, { duration: 6000 });
                    loader.hide();
                })
                .catch((err) => {
                    console.log("[Backend] Verify email error: ", err);
                    loader.hide();
                    this.$toasted.show("Server Error", {
                        duration: 6000,
                        icon: "clear",
                        type: "error",
                    });
                });
        },

        signUp(role, admin) {
            console.log('signUp');
            console.log("this.usertype ", this.usertype);
            console.log("this.user_username", this.user_username);
            console.log("this.email", this.email);
            console.log("this.mobile", this.mobile);

            let split_name = this.user_username.split(' ');

            if (split_name.length == 2 || split_name.length == 3) {
                this.user_first_name = split_name[0],
                    this.user_last_name = split_name[1];
            }

            if (this.usertype === 'Email') {
                this.mobile = null
                this.signUpWithEmail()
            }
            else {
                this.email = ''
                this.password = 'password'
                this.signUpWithMobile()
            }




        },

        captchaVerify(event) {
            // this.rememberMe();
            console.log(`Captcha Verify Event :`, event);
            this.captchaValue = event;
        },
        captchaExpired() {
            console.log("Captcha Expired");
            this.captchaValue = null;
        },
        captchaError() {
            console.log("Captcha Error");
            this.captchaValue = null;
        },
    },
    beforeMount() {
        this.recaptchaSize = this.isMobileMenu ? 'compact' : 'normal'
    },
    mounted() {

    },
    beforeUnmount() {

    },
    computed: {
    }

}
</script>

<style>
.login-container {
    max-width: 500px;
    width: 100%;
    /* min-height: 100%; */
    /* max-height: 600px; */
    display: flex;
    /* bottom: 9%; */
    /* height: 100%; */
    /* min-height: 65%; */
    border-radius: 10px;
    background: var(--neutral-white, #FFF);
    box-shadow: 0px 50px 100px 0px rgba(0, 0, 0, 0.10);
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    /* font-family: Gilroy-Bold; */
}

.login-header {
    color: var(--neutral-black, #141522);
    text-align: center;
    /* font-family: Gilroy-Bold; */
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    /* 36px */
}

.login-content {
    width: 100%;
}


.btn-secondary {
    color: #000;
    background-color: #A4A4A4;
    ;
    border-color: #A4A4A4;
}

.login-type-dropdown {
    width: 92%;
}

.login-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
</style>